import { Create } from "Features/Downloads/components/Create/Create";
import { List } from "Features/Downloads/components/List/List";
import { Route, Routes } from "react-router-dom";

export const Downloads = () => {
  return (
    <>
      <Create />
      <Routes>
        <Route path="/" element={<List />} />
      </Routes>
    </>
  );
};
