import { useSubscription } from "@apollo/client";
import { Content } from "Components/Content/Content";
import { Page } from "Components/Page/Page";
import { PageLoader } from "Components/PageLoader/PageLoader";
import { Table } from "Components/Table/Table";
import { GET_DOWNLOADS } from "GraphQL/operations";
import { Link } from "react-router-dom";

export const List = () => {
  const { data, loading } = useSubscription(GET_DOWNLOADS);

  const renderDownloads = () => {
    const headers = data?.downloads[0] ? Object.keys(data.downloads[0]) : [];
    const rows = data.downloads || [];
    const newRows = rows.map((row: any) => {
      const newRow = { ...row };
      newRow["folder_name"] = newRow["metadata"]?.folderName || "";
      newRow["folder_id"] = {
        component: (
          <Link
            to={`https://drive.google.com/drive/folders/${newRow["metadata"]?.folderId}`}
            target="_blank"
          >
            {newRow["metadata"]?.folderId || ""}
          </Link>
        ),
      };
      newRow["created_at"] = new Date(newRow["created_at"]).toLocaleString();
      return newRow;
    });

    headers.push("folder_name", "folder_id");
    return (
      <Table
        headers={headers}
        data={newRows}
        ignoreFields={["metadata", "__typename"]}
      />
    );
  };

  return (
    <Page opaque={false} height="85%">
      {!loading ? (
        renderDownloads()
      ) : (
        <Content>
          <PageLoader transparent={false} />
        </Content>
      )}
    </Page>
  );
};
