import React, { useEffect } from "react";
import "./App.css";
import { Logout } from "Features/Logout/Logout";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { NewLogin } from "Features/NewLogin/NewLogin";
import { auth } from "Firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { ApolloProvider } from "@apollo/client";
import { getApolloClient } from "GraphQL/config";
import { Downloads } from "Features/Downloads/Downloads";

function App() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user && !loading) {
      navigate("/login");
    }
  }, [user, navigate, loading]);

  return (
    <ApolloProvider client={getApolloClient("")}>
      <Routes>
        <Route path="/login" element={<NewLogin />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/" element={<Navigate to={"/downloads"} />} />
      </Routes>
    </ApolloProvider>
  );
}

export default App;
