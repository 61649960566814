import { gql } from "@apollo/client";

export const GET_DOWNLOAD_TYPES = gql`
  query GetJobTypes {
    download_types: anime_service_download_types {
      value
      label
    }
  }
`;

export const GET_DOWNLOAD_STATUSES = gql`
  query GetJobStatuses {
    download_statuses: anime_service_download_statuses {
      value
      label
    }
  }
`;

export const GET_DOWNLOADS = gql`
  subscription GetJobs($filter: [String!] = ["DRIVE"], $offset: Int) {
    downloads: anime_service_downloads(
      order_by: { created_at: desc }
      where: { type: { _in: $filter } }
      offset: $offset
      limit: 40
    ) {
      id
      type
      created_at
      status
      metadata
    }
  }
`;

export const GET_DOWNLOAD = gql`
  query GetJob($id: uuid) {
    downloads: anime_service_downloads(where: { id: { _eq: $id } }) {
      id
      download_status {
        value
        label
      }
      profile
      is_favorite
      download_type {
        value
        label
      }
      created_at
      metadata
      tag_links {
        tag {
          name
        }
      }
    }
  }
`;

export const CREATE_DOWNLOAD = gql`
  mutation CreateJob($created_by: Int, $type: String, $metadata: json) {
    insert_downloads: insert_anime_service_downloads(
      objects: { created_by: $created_by, type: $type, metadata: $metadata }
    ) {
      returning {
        id
        metadata
        status
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser($firebase_auth_id: String) {
    users: anime_service_users(
      where: { firebase_auth_id: { _eq: $firebase_auth_id } }
    ) {
      first_name
      last_name
      email
      firebase_auth_id
      is_active
      id
    }
  }
`;

export const DELETE_DOWNLOADS = gql`
  mutation DeleteJobs($ids: [uuid!]) {
    delete_downloads: delete_anime_service_downloads(
      where: { id: { _in: $ids } }
    ) {
      returning {
        id
      }
    }
  }
`;

export const GET_DOWNLOADS_COUNT = gql`
  query JobsCount {
    downloads_aggregate: anime_service_downloads_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_DOWNLOAD_FAVORITE_COUNT = gql`
  query GetJobsFavoriteCount {
    downloads_aggregate: anime_service_downloads_aggregate(
      where: { is_favorite: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const TRIGGER_DOWNLOAD = gql`
  mutation trigger($userId: String!, $folderId: String!, $downloadId: String!) {
    trigger_download(
      args: { user_id: $userId, folder_id: $folderId, download_id: $downloadId }
    ) {
      fname
      error
      message
    }
  }
`;
