import React from "react";
import "./styles.scss";
export type TTableProps = {
  headers: string[];
  data: any[];
  ignoreFields?: string[];
};

export const Table: React.FC<TTableProps> = ({
  headers,
  data = [],
  ignoreFields = [],
}) => {
  const renderHeaders = () => {
    return headers.map((header, index) => {
      if (ignoreFields.includes(header)) return null;
      return <th key={index}>{header.replace("_", " ")}</th>;
    });
  };

  const renderRow = (row: any) => {
    return (
      <tr key={row["id"]}>
        {headers.map((cell: any, index: number) => {
          if (ignoreFields.includes(cell)) return null;
          if (typeof row[cell] === "string")
            return <td key={index}>{row[cell]}</td>;
          if (typeof row[cell] === "object") {
            const { component } = row[cell];
            return <td key={index}>{component}</td>;
          }
          return "";
        })}
      </tr>
    );
  };

  const renderData = () => {
    return data.map((row: any) => {
      return renderRow(row);
    });
  };

  return (
    <table className="table" cellPadding="0" cellSpacing="0" border={0}>
      <thead>
        <tr>{renderHeaders()}</tr>
      </thead>
      <tbody className="tb">{renderData()}</tbody>
    </table>
  );
};
