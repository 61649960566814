import { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_DOWNLOAD } from "GraphQL/operations";
import "./styles/styles.css";

const extractFolderId = (str: string): string => {
  const blown = str.split("/");
  const folderIndex = blown.indexOf("folders");
  console.log(folderIndex);
  return blown[folderIndex + 1];
};

export const Create = () => {
  const [input, setInput] = useState("");

  const [createDownload, { loading }] = useMutation(CREATE_DOWNLOAD);

  const handleOnSubmit = async () => {
    const folderId = extractFolderId(input);
    if (!folderId) return;
    const variables = {
      type: "DRIVE",
      created_by: 1,
      metadata: {
        folderId: folderId,
      },
    };
    await createDownload({
      variables,
    });
    setInput("");
  };

  const handleInputOnChange = async ({ target }: any) => {
    setInput(target.value);
  };

  return (
    <div className="controls-container">
      <input
        className="input"
        disabled={loading}
        placeholder={"copy link here"}
        value={input}
        onChange={handleInputOnChange}
      ></input>
      <button onClick={handleOnSubmit} className="app-button">
        DOWNLOAD
      </button>
    </div>
  );
};
